import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
/* import NotFound from "pages/404"; */
import "./App.css";
import ScrollToTop from "./components/scroll-to-top";
import Home from "./pages/home";
import Services from "./pages/services";
import ServicesSingle from "./pages/services-single";
import Team from "./pages/team";
import About from "./pages/about";
import Projects from "./pages/projects";
import ProjectsSingle from "./pages/projects-single";
import News from "./pages/news";
import NewsSingle from "./pages/news-single";
import Newsletter from "./pages/newsletter";
import Careers from "./pages/careers";
import Page from "./pages/page";
import { OpenCloseContactContext } from "./contexts/contact";
import { LoaderContext } from "./contexts/loader";
import { WEB_ROUTES } from "./routes/web-routes";

function App() {
  const [openCloseContact, setOpenCloseContact] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      <OpenCloseContactContext.Provider
        value={{ openCloseContact, setOpenCloseContact }}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* PUBLIC ROUTES */}
            <Route index exact path={WEB_ROUTES.HOME} element={<Home />} />
            <Route path={WEB_ROUTES.ABOUT} element={<About />} />
            <Route path={WEB_ROUTES.TEAM} element={<Team />} />
            <Route path={WEB_ROUTES.SERVICES} element={<Services />} />
            <Route
              path={WEB_ROUTES.SERVICES + `/:service`}
              element={<ServicesSingle />}
            />
            <Route path={WEB_ROUTES.PROJECTS} element={<Projects />} />
            <Route
              path={WEB_ROUTES.PROJECTS + `/:project`}
              element={<ProjectsSingle />}
            />
            <Route
              path={WEB_ROUTES.PROJECTS_TYPE + `/:type`}
              element={<Projects />}
            />
            <Route path={WEB_ROUTES.NEWS} element={<News />} />
            <Route path={WEB_ROUTES.NEWS + `/:news`} element={<NewsSingle />} />
            <Route path={WEB_ROUTES.NEWSLETTER} element={<Newsletter />} />
            <Route path={WEB_ROUTES.CAREERS} element={<Careers />} />

            {/* <Route exact path="/404" element={<NotFound />} /> */}
            <Route exact path="/:slug/*" element={<Page />} />
            <Route path="/*" element={<Home />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </OpenCloseContactContext.Provider>
    </LoaderContext.Provider>
  );
}

export default App;
